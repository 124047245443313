import {HOST} from '../Url'
export const UPLOAD_REPORTS_TO_APPOINTMENT = id => `${HOST}/appointments/${id}/upload`;
export const BRANCH_LOGIN = `${HOST}/login`;
export const ENTERPRISE_LOGIN = `${HOST}/enterprise/login`;
export const UPDATE_APPOINTMENT = (id, action) => `${HOST}/appointments/${id}/${action}`
export const FETCH_APPOINTMENT_URL = (filterUrl) => { 
  if(["new1","completed"].includes(filterUrl)){
    return  `${HOST}/appointments/waiting`;
  }
  if(filterUrl === 'new') return `${HOST}/appointments`;
  return `${HOST}/appointments/${filterUrl}`
}
export const EXPORT_TO_CSV = `${HOST}/appointments/master_download`
import React, {Component} from 'react'
import { Modal, Alert, Icon, Upload, Button } from 'antd';
import {HOST} from '../../Url'
import {getToken} from '../../Auth'
import Api from "../../services/api";
import {message} from 'antd'
class UpdateRecordsModal extends Component {

  constructor(props) {
    super(props)
    this.state = { missingReports: undefined, 
                  alertMessage: "", checkedValues: [], loading: false, fileList: [], uploading: false, invalidFileList:[],
                  confirmationModelVisible:false}
  }

  componentDidMount() {
    if(this.props.appointment !== undefined) {
      this.setState({missingReports: this.props.appointment.list_of_missing_reports})
    }
  }
  
  renderAlert(){
    const { invalidFileList, alertMessage } = this.state;
    if (invalidFileList.length > 0){
      let suffix_text = invalidFileList.length === 1 ? ' is not supported file.' : ' are not supported files.';
      let unsupported_files = invalidFileList.join(', ');
      return  (<Alert message={ unsupported_files + suffix_text }
        type="error"
        closable
      />)
    }
    if(alertMessage !== "") {
      return  (<Alert message={alertMessage}
          type="error"
          closable
        />)
    } else {
      return (<div></div>)
    }
  }

  handleUpload = () => {
    this.setState({confirmationModelVisible: true});
    return;
  };

  onUploadSuccess = response => {
    this.setState({ fileList: [], loading: false }, function() {
      this.updateRecords();
    });
  };

  onUploadFailure = error => {
    this.setState({ loading: false });
    message.error('Reports Upload failed.',5);
    this.props.closeModal();
  };

  handleConfirm = () => {
    const { fileList } = this.state;
    const formData = new FormData();
    fileList.forEach(file => {
      formData.append('files[]', file);
    });

    this.setState({
      uploading: true,
      invalidFileList:[],
      confirmationModelVisible:false
    });

    Api.uploadReportsToAppointment(
      { id: this.props.appointment.id, data: formData },
      this.onUploadSuccess,
      this.onUploadFailure
    );
  };

  updateRecords(){
    let id = this.props.appointment.id
    this.setState({loading: true});
    const { onSuccess, closeModal }  = this.props;
    fetch(HOST + "/appointments/" + id + "/update_reports", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-provider-key': getToken()
      },
      body: JSON.stringify({
        received_reports: this.state.missingReports,
      })
    }).then(response => response.json())
      .then(json => {     
        this.setState({loading: false})
        onSuccess();
        closeModal();
        if(this.props.reloadCurrentLoc === true){
          window.location.reload();
        }
      })
      .catch((error) => {
        this.setState({loading: false, alertMessage: "Operation failed"}) 
        console.error(error);
      });
  }

  handleChange(checkedValues) {
    this.setState({checkedValues: checkedValues})
  } 
  
  handleSelectButton = (e) => {
    this.setState({invalidFileList:[]});
  }
  
  renderUploadModal = () => {
    const { uploading, fileList, invalidFileList } = this.state;
    const props = {
      onRemove: file => {
        this.setState(state => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      },
      beforeUpload: file => {
        if(!file.name.toLowerCase().endsWith('.pdf')){
            invalidFileList.push(file.name);
            this.setState({invalidFileList:invalidFileList});
            return false;
        }
        this.setState(state => ({
          fileList: [...state.fileList, file],
        }));
        return false;
      },
      fileList,
    };
    return (
      <Modal 
        title="Update Records"
        visible={this.props.visible}
        maskClosable={false}
        confirmLoading={this.state.loading}
        onCancel={this.props.closeModal}
        footer={null}
        style={{marginTop: '100px'}}
      >
        {this.renderAlert()}
        <p className="ant-upload-text">You are uploading reports for <b> {this.props.appointment.name} ({this.props.appointment.age_and_gender}) - {this.props.appointment.appointment_id} </b></p>
        <Upload {...props} 
                multiple = {true} 
                accept="application/pdf">
          <Button onClick={this.handleSelectButton}>
            <Icon type="upload" /> Select Reports
          </Button>
          <br/>
          <font size="3" style={{color:'#2168f3'}} >You can upload multiple reports, supported format is PDF</font>

        </Upload>
        <Button
          type="primary"
          onClick={this.handleUpload}
          disabled={fileList.length === 0}
          loading={uploading}
          style={{ marginTop: 16 }}
        >
          {uploading ? 'Uploading' : 'Upload'}
        </Button>
      </Modal>
    )
  }

  handleCancel = () => {
    this.setState({confirmationModelVisible:false});
  }

  renderConfirmationModal = () => {
    return(
      <Modal
          title="Confirm upload"
          visible={this.state.confirmationModelVisible}
          onOk={this.handleConfirm}
          onCancel={this.handleCancel}
          style={{'top':'0px'}}
          footer={[
            <Button key="Cancel" onClick={this.handleCancel}>
              Cancel
            </Button>,
            <Button key="Confirm" type="primary" onClick={this.handleConfirm}>
              Confirm
            </Button>,
          ]}
        >
          <p>Please make sure you have uploaded all the available reports. In case of any pending reports you can upload them again under the "Partially Received Reports" tab once our team reviews the present uploaded reports</p>
        </Modal>
    )
  }
  
  render() {
    if(!this.props.visible){
      return (<div></div>)
    }
    if(this.state.missingReports !== undefined){
      return (<div>{this.renderUploadModal()}
      {this.state.confirmationModelVisible === true && this.renderConfirmationModal()}</div>)
    }
    return(<div></div>)
  }
}

export default UpdateRecordsModal
import { 
  UPLOAD_REPORTS_TO_APPOINTMENT,
  BRANCH_LOGIN,
  ENTERPRISE_LOGIN,
  UPDATE_APPOINTMENT
} from "../routes/api";
import request from "../utils/request";
export default class Api {
    static uploadReportsToAppointment = (options, onSuccess, onError) => {
        request(
          {
            ...options,
            url: UPLOAD_REPORTS_TO_APPOINTMENT(options.id),
            method: "post"
          },
          onSuccess,
          onError
        );
    };

    static updateRecords = (options, onSuccess, onError) => {
      request(
        {
          ...options,
          url: UPDATE_APPOINTMENT(options.id, options.action),
          method: 'post'
        },
        onSuccess,
        onError
      );
    };

    static branchLogin = (options, onSuccess, onError) => {
      request(
        { ...options, url: BRANCH_LOGIN, method: "post" },
        onSuccess,
        onError
      );
    };

    static fetchAppointments = (url, options, onSuccess, onError) => {
      request(
        {...options, url: url, method: 'get'},
        onSuccess,
        onError
      );
    }

    static exportToCSV = (url, options, onSuccess, onError) => {
      request (
        {...options, url: url, method: 'get'},
        onSuccess,
        onError
      )
    }

    static enterpriseLogin = (options, onSuccess, onError) => {
    request(
      { ...options, url: ENTERPRISE_LOGIN, method: "post" },
      onSuccess,
      onError
    );
  };
}
import React, {useState} from "react";
import { Modal, Button, Row, Input, message } from "antd";
import moment from "moment";
import {HOST} from '../../Url'
import {getToken} from '../../Auth'

function ConfirmActionModal(props) {
  const { visible, onCancel, onSuccess, selectedAppointment } = props;
  const [comment, setConfirmComment] = useState(null);
  const [loading, setLoading] = useState(false);


  const handleAction=()=>{
    if(selectedAppointment) {
      fetch(HOST + "/appointments/" + selectedAppointment.id + "/confirm", {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'x-provider-key': getToken()
        },
        body: JSON.stringify({
          comment
        })
      }).then(response => response.json())
        .then(json => {     
          setLoading(false);
          setConfirmComment("");
          onSuccess();
        })
        .catch((error) => {
          message.error(error)
          setLoading(false);
        });
    } else {
      message.error('something went wrong')
    }
  }

  const handleCancel = () => {
    setConfirmComment('');
    onCancel();
  }
  const getComment = (e) => {
    setConfirmComment(e.target.value);
  }

  const Title = () => {
    return (
      <>
        <strong
          style={{
            color: "#324467",
            marginBottom: "10px",
            fontSize: "20px",
          }}
        >
          Appointment Confirmation
        </strong>
        <p style={{ color: "#4D6189", marginTop:'10px' }}>
          You are about to Confirm the following appointment
        </p>
      </>
    );
  };

  const { TextArea } = Input;

  return (
    <Modal
      title={<Title />}
      visible={visible}
      onOk={onSuccess}
      maskClosable={false}
      maskStyle={{backgroundColor:'rgba(0, 0, 0, 0.5)'}}
      onCancel={handleCancel}
      style={{ top: "100px" }}
      footer={[
        <Button key="Confirm" type="primary" fontWeight="lg" onClick={handleAction}>
          <strong>Confirm Appointment</strong>
        </Button>
      ]}
    >
      <Row
        style={{
          background: "#F5F7F9",
          borderRadius: "5px",
          height: "80px",
          marginBottom: "10px",
          padding: "15px",
        }}
        type="flex"
        justify="space-between"
      >
        <div>
          <p style={{ fontWeight: "600", fontSize: "15px", color: "#324467" }}>
         {selectedAppointment?.appointment_id}
          </p>
          <p style={{ color: "#8895AF", marginTop: "-12px" }}>{selectedAppointment?.package_name}</p>
        </div>
        <p style={{ fontWeight: "600", fontSize: "15px", color: "#324467" }}>
        {moment(selectedAppointment?.appointment_date).format('DD MMM, YYYY')}
        </p>
      </Row>
      <p style={{ color: "#7C8CAB", fontSize:'14px',fontWeight:'600',letterSpacing:'1px', marginBottom: "6px", marginTop: "15px"}}>
        ADD NOTES
      </p>
      <TextArea
        className='notes'
        placeholder="Add notes for the appointment..."
        value={comment}
        onChange={getComment}
        rows="10"
        style={{ resize: "none", fontSize:'16px' }}
      />
    </Modal>
  );
}

export default ConfirmActionModal;

import React, {useState} from "react";
import { Modal, Button, Row, Input, Select } from "antd";
import moment from "moment";
import {HOST} from '../../Url'
import {getToken} from '../../Auth'
const { Option } = Select;
const { TextArea } = Input;


function RejectActionModal(props) {
  const { visible, onCancel, onSuccess, selectedAppointment } = props;
  const [comment, setRejectComment] = useState("");
  const [reason, setRejectReason] = useState("");
  const [loading, setLoading] = useState(false);

  const handleAction=()=>{
    fetch(HOST + "/appointments/" + selectedAppointment.id + "/reject", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-provider-key': getToken()
      },
      body: JSON.stringify({
        reason,comment
      })
    }).then(response => response.json())
      .then(json => {     
        setLoading(false);
        setRejectComment("");
        setRejectReason("");
        onSuccess();
      })
      .catch((error) => {
        setLoading(false);
      });
  }
  const handleCancel = () => {
    setRejectComment('');
    setRejectReason('');
    onCancel();
  }

  const Title = () => {
    return (
      <>
        <strong
          style={{
            fontWeight: "400px",
            color: "#324467",
            fontSize: "20px",
          }}
        >
          Appointment Rejection
        </strong>
        <p style={{color: "#4D6189", marginTop:'10px'}}>
          You are about to {!reason ? 'cancel' : 'reject'} the following appointment
        </p>
      </>
    );
  };
  const placeColor=reason ? '#324467' : '#A4B0CC';
  return (
    <Modal
      title={<Title />}
      visible={visible}
      onOk={onSuccess}
      maskClosable={false}
      onCancel={handleCancel}
      style={{ top: "100px" }}
      footer={[
        <Button key="Confirm" type="primary" fontWeight="lg" disabled={!reason} onClick={handleAction} >
          <strong>Confirm Rejection</strong>
        </Button>
      ]}
    >
      <Row
        style={{
          background: "#F5F7F9",
          borderRadius: "5px",
          height: "80px",
          marginBottom: "10px",
          padding: "15px",
        }}
        type="flex"
        justify="space-between"
      >
        <div>
          <p style={{ fontWeight: "600", fontSize: "15px", color: "#324467" }}>
             {selectedAppointment?.appointment_id}
          </p>
          <p style={{ color: "#8895AF", marginTop: "-12px" }}>{selectedAppointment?.package_name}</p>
        </div>
        <p style={{ fontWeight: "600", fontSize: "15px", color: "#324467" }}>
        {moment(selectedAppointment?.appointment_date).format('DD MMM, YYYY')}
        </p>
      </Row>
      <p style={{ color: "#7C8CAB", fontSize:'14px',fontWeight:'600',letterSpacing:'1px', marginBottom: "6px", marginTop: "15px"}}>
        SELECT A REASON
      </p>
      <Select
        style={{
          width: "100%",
          marginRight: "10px",
          borderRadius:'5px',
          height:'50px',
          backgroundColor:' #F5F7F9',
          fontWeight:'bold',
          fontSize:'16px',
          color:placeColor
        }}
        onChange={(value)=>{setRejectReason(value)}}
        value={!reason ? 'Select reason for cancellation' : reason}
      >
        <Option value="Location not serviceable" style={{fontWeight:'bold', color:'#4D6189'}} >
           Location not serviceable
        </Option>
        <Option value="Machine failure" style={{fontWeight:'bold', color:'#4D6189'}} >
           Machine failure
        </Option>
        <Option value="Doctor unavailable" style={{fontWeight:'bold',color:'#4D6189'}}>
           Doctor unavailable
        </Option>
        <Option value="Stock issues" style={{fontWeight:'bold', color:'#4D6189'}}>
           Stock issues
        </Option>
        <Option value="Slot not available" style={{fontWeight:'bold', color:'#4D6189'}}>
           Slot not available
        </Option>
        <Option value="Non-fasting candidates" style={{fontWeight:'bold', color:'#4D6189'}}>
           Non-fasting candidates
        </Option>
        <Option value="Regional Holidays" style={{fontWeight:'bold', color:'#4D6189'}}>
           Regional Holidays
        </Option>
        <Option value="Other" style={{fontWeight:'bold', color:'#4D6189'}}>
           Other
        </Option>
      </Select>
      <p style={{ color: "#7C8CAB", fontSize:'14px',fontWeight:'600', letterSpacing:'1px', marginBottom: "6px", marginTop: "15px" }}>
        ADDITIONAL COMMENTS
      </p>
      <TextArea rows="10" value={comment} onChange={(e)=>{setRejectComment(e.target.value)}} style={{ resize: "none" }} />
    </Modal>
  );
}

export default RejectActionModal;

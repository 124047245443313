function isLoggedIn() {
  const token = localStorage.getItem('EK_SESSION_TOKEN')
  if(token) {
    return true
  } else {
    return false
  }
}

function getToken() {
  return localStorage.getItem('EK_SESSION_TOKEN')
}

function storeDisplayName(username){
  localStorage.setItem('USERNAME',username);
}

function getDisplayName(){
  return localStorage.getItem('USERNAME')
}

function storeToken(token) {
  localStorage.setItem('EK_SESSION_TOKEN', token)
}

function logout() {
  localStorage.removeItem('EK_SESSION_TOKEN')
  localStorage.removeItem('USERNAME')
  localStorage.removeItem('lastActive');
}

export { isLoggedIn, getToken, storeToken, logout, storeDisplayName, getDisplayName}


import React, { useEffect, useState } from 'react';
import { Button, Drawer, Row} from 'antd';
import { caratDownGray, caratUpGray, CircleCheckedGreen, CircleCheckedGrey, LeftArrow } from '../../assets/icons';


function ShowPendingTestModal(props) {
  const { visible, onClose, selectedAppointment, handleUpload } = props;
  const appointment_tests = selectedAppointment?.appointment_tests

  const [exploreId, setExploreId] = useState([]);
  const [selected, setSelected] = useState({})

  useEffect(() => {
    if(!appointment_tests) return
    const preSelected = Object.keys(appointment_tests).reduce((prev, curr)=>{
      prev[curr] = appointment_tests[curr]?.map(({ result_received, test_component }) => ({result_received, test_component}))
      return prev;
    }, {})
    setSelected({...preSelected})
  }, [appointment_tests]);

  const handleExplore = (newId) => {
    if(exploreId.includes(newId)){
      setExploreId(exploreId.filter(id => id !== newId))
    } else {
      setExploreId([...exploreId, newId])
    }
  }
    const DrawerTitle = () =>{
      return (
        <Row type='flex' justify='space-between'>
          <div style={{marginTop: '6px'}}>
            <img src={LeftArrow} onClick={onClose} alt='close' style={{marginBottom: "7px", marginRight: "20px", cursor: "pointer"}} />
            <span style={{fontWeight: "bolder", fontSize:"22px", color: "#324467"}}>Reports status</span>
          </div>
          <Button type="primary" disabled={false} onClick={() => {handleUpload(selectedAppointment, 'received')}} style={{border:'none', borderRadius: '8px', fontWeight: 'bold'}}>Upload pending reports</Button>
        </Row>
       )
    }

    return (
      <Drawer title={DrawerTitle()} closable={false} placement="right" onClose={onClose} visible={visible} width={525}>
        <h1 style={{fontSize: "15px", fontWeight: "bold", color: "#7C8CAB", marginBottom: "20px"}}>LIST OF TESTS</h1>
        {Object.keys(selected)?.length > 0 ?
          Object.keys(selected).map((test_name) => {
            return(
              <>
                <Row type='flex' justify='space-between' key={test_name}>
                  <p style={{fontSize: "15px", fontWeight: "bold", color: "#324467", maxWidth: "400px", marginBottom: "20px", marginLeft: "10px"}}>{test_name}</p>
                  <img src={exploreId.includes(test_name) ? caratUpGray : caratDownGray} alt='arrow' onClick={()=>handleExplore(test_name)} height={12}  style={{marginBottom: "20px", cursor: "pointer"}} />
                </Row>
                {exploreId.includes(test_name) && selected[test_name]?.map(({result_received, test_component})=>{
                  return(
                    <Row type='flex' justify='start' style={{marginLeft: "26px"}}>
                      <img src={result_received ? CircleCheckedGreen : CircleCheckedGrey} alt='arrow' style={{marginRight: "4px", marginBottom: "20px"}} />
                      <p style={{fontSize: "15px", fontWeight: "bold", color: "#324467", maxWidth: "340px", marginBottom: "20px", marginLeft: "10px"}}>{test_component ?? test_name}</p>
                    </Row>
                  )
                })}
              </>
            )
          })
          : <span style={{color: "red", fontWeight: "bold"}}>No tests for this appointment</span>
        }
      </Drawer>
    );
  }

export default ShowPendingTestModal;
import React, { Component,Fragment } from 'react';
import TopMenu from '../components/TopMenu'
import { logout, getDisplayName} from '../Auth'
import { Row, Col, Icon, Button, Input, Layout, Spin } from 'antd'
import AppointmentTable from '../components/AppointmentsTable';
import AppointmentsMenu from '../components/AppointmentsMenu';
import DownloadCsvModal from '../components/modals/DownloadCsvModal';
import IdleTimer from 'react-idle-timer';
import CryptoJS from 'crypto-js';
import moment from 'moment';
import UserAlert from '../components/atoms/UserAlert';
const RELEASE_DATE = '10-02-2023'

const { Header, Content} = Layout;
class AppointmentsPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      current: "new",
      total: 0,
      visible: false,
      masterDownload:false,
      globalSearchTerm: '',
      searchTerm: '',
      loading: true,
      idleTimer: null,
      timeout: 1*1000*60*60
    }
  }

  handleClick = (e) => {
    let key = e.key;
    let loading = this.state.current===key ? false : true;
    this.setState({currentPage: 1, loading: loading, current: key});
  }

  updateTotal = (total) => {
    this.setState({total: total});
  }

  setLoading = (loading) => {
    this.setState({loading: loading});
  }

  signout(){
    logout()
  }

  onSearch = (event) => {
    const { globalSearchTerm } = this.state;
    const current = globalSearchTerm === '' ? this.state.current : 'all';
    this.setState({searchTerm: globalSearchTerm, current});
  }

  clearSearch = (event) => {
    this.setState({globalSearchTerm: '' },()=>{
      this.onSearch(event);
    });
  }

  onChangeMasterDownload = (showModal) => {
    this.setState({masterDownload: showModal});
  }
   onIdle = () => {
    this.signout();
    const {history} = this.props;
    history.push('/login');
    
};
   encrypt = () => {
    let encrypted = CryptoJS.AES.encrypt(moment(new Date()).toISOString(), "Secret Passphrase").toString();
    localStorage.setItem('lastActive',encrypted);
   }
   decrypt = () => {
    const lastActive = localStorage.getItem('lastActive');
    if(lastActive){
    const decryptedData = CryptoJS.AES.decrypt(lastActive, "Secret Passphrase")
    return decryptedData.toString(CryptoJS.enc.Utf8);
    }
    return null;
   }
   componentDidMount = () => {
    const sessionCompleted = this.decrypt();
    if (sessionCompleted) {
        const startTime = moment(this.decrypt());
        const endTime = moment(new Date());
        const hoursDiff = endTime.diff(startTime, "hours");
        if (hoursDiff >= 1) {
          this.onIdle();
        } else {
          this.encrypt();
          this.setLoading(false);
        }
    } else {
        this.encrypt();
        this.setLoading(false);
    }
   }
 onAction = () => {
  this.encrypt();
 };

  render() {
    const { masterDownload,idleTimer,timeout } = this.state;
    const renderState = localStorage.getItem('releaseDate')  === RELEASE_DATE
    return(
    <Fragment>
      <IdleTimer
        ref={idleTimer}
        onIdle={this.onIdle}
        onActive={this.onActive}
        onAction={this.onAction}
        timeout={timeout} />
      <Layout>
      <Header > 
       <TopMenu signout={this.signout.bind(this)}/>
        <font size={4} face="Bedrock" style={{ 'color':'rgba(255, 255, 255, 0.65)'}}> {getDisplayName()} </font>
       </Header>
  
       <Content>

            <div style={{ background: '#fff', padding: 24, minHeight: 380 }}>  
              { !renderState &&
                <UserAlert 
                  icon='yellow'
                  btnText='Refresh Page' 
                  para={<span style={{ fontSize : '16px', padding : '0px 0px', marginRight : '12px', fontWeight : '600'}} >
                          New Update available. 
                        <a style={{ color : '#0951e1' }} 
                            onClick={() => {
                            localStorage.setItem('releaseDate', RELEASE_DATE)
                            window.location.reload(true);
                          }}>
                          Click here
                        </a> to load latest update.</span>}
                  btnHandler={() => {
                      localStorage.setItem('releaseDate', RELEASE_DATE)
                      window.location.reload(true);
                      }
                    }  
                  />
              }
            <Row type='flex' justify='space-between'>
              <Col span={14} style={{fontFamily:"Bedrock"}}>
                <h1> Appointments  <Button type="primary" shape="circle" icon="download" onClick={() => { this.setState({masterDownload: true })}}/></h1>
                <DownloadCsvModal showModal = {masterDownload} onChangeShowModal = {this.onChangeMasterDownload}/>
                <p> If you are facing any issue with this dashboard, please contact us at +91 040 6817 4274 </p>
              </Col>
              <Col span={10}>
                <Input
                  size='large'
                  placeholder="Search by Mobile Number, EKID, AppointmentId, and National Id"
                  value={this.state.globalSearchTerm}
                  style={{ width: '100%', marginTop: '20px', marginBottom: '20px', fontFamily:"Bedrock" }}
                  onChange = {event => this.setState({globalSearchTerm: event.target.value})}
                  onPressEnter={value => this.onSearch(value)}
                  suffix={
                    <div style={{ marginLeft:'5px'}}>
                      <Icon type="search" style={{ color: 'rgba(0,0,0,.45)', marginRight: '5px' }} onClick = {this.onSearch}/>
                      <Icon type="close" style={{ color: 'rgba(0,0,0,.45)' }} onClick = {this.clearSearch}/>
                    </div>
                  }
                />
                
              </Col>
            </Row>
            <Spin spinning={this.state.loading} tip='loading...'>
              <AppointmentsMenu total= {this.state.total} current={this.state.current} handleClick={this.handleClick}/>
              <AppointmentTable rowKey="id" handleClick={this.handleClick} searchQuery={this.state.searchTerm} currentTab={this.state.current} updateTotal =  {this.updateTotal} loading = {this.state.loading} setLoading={this.setLoading}/>
            </Spin>
          </div>
        </Content>
      </Layout>
      </Fragment>
    );
  }
}

export default AppointmentsPage;

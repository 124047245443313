import { Menu, Icon } from 'antd';
import React from 'react';
import {Link} from 'react-router-dom'
import {logout} from '../Auth'
const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const TopMenu = () => {
  return (
    <Menu
        theme="dark"
        mode="horizontal"
        style={{ lineHeight: '64px', fontFamily:'Bedrock', float: 'right' }}
      >
        <SubMenu title={<span>Appointments <Icon type="caret-down"/></span>}>
            <MenuItemGroup>
                <Menu.Item key="1"><Link to="/appointments">Appointments </Link></Menu.Item>
            </MenuItemGroup>
        </SubMenu>
        <Menu.Item key="10"><a href="#" onClick={() => { logout()}}> Logout </a></Menu.Item>
      </Menu>
    );
}

export default TopMenu;
import React, {Component} from 'react'
import { Row, Col, Layout, Input, Icon, Button, message } from 'antd';
import {HOST} from '../Url'
const { Content } = Layout;
class ResetPassword extends Component {

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.refs.newPassword.input.value !== this.refs.confirmPassword.input.value) {
      return message.success("Please enter same password in both fields")
    }
    console.log(this.refs.newPassword.input.value)
    this.submitPassword(this.refs.newPassword.input.value)
  }
  submitPassword (password) {
    let that = this
    let type = this.props.match.params.type
    fetch(HOST + "/reset_password?type="+type ,{
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token: this.props.match.params.id,
          password: password
        })
      })
      .then(response => {
        if (response.status === 200) {
            message.success("Password updated successfully")
            that.props.history.push('/login')
        } else {
          message.error("Link has expired")
        }
      }, this)
      .catch((error) => {
          console.error(error);
        });
  }
  render() {
    return (
      <Layout>
       <Content style={{ padding: '0 0px', marginTop: 0 }}>
          <div style={{ background: '#fff', padding: 50, minHeight: 380 }}>
            <Row type="flex" justify="space-around" align="middle">
              <Col span={7}>
                <h2> Reset Password </h2>
                <h4>New Password</h4>
                <Input type='password' ref='newPassword' prefix={<Icon type="user" style={{ fontSize: 13 }} />} placeholder="password" />
                <br/>
                <br/>
                <h4>Confirm Password</h4>
                <Input type='password' ref='confirmPassword' prefix={<Icon type="user" style={{ fontSize: 13 }} />} placeholder="password" />
                <br/>
                <br/>
                <Button type="primary" htmlType="submit" onClick={this.handleSubmit.bind(this)}> Submit </Button>
              </Col>
            </Row>
          </div>
        </Content>
      </Layout>);
  }
}

export default ResetPassword
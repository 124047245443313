import React from 'react';
import {Icon, Menu, Row } from 'antd'
const AppointmentsMenu  = React.memo(({total, current, handleClick}) => {

  const menuItem = (key, icon, placeholder) =>{
    return (
      <Menu.Item key={key}>
        <Icon type={icon} />{placeholder} {current === key ? `(${total})` : ''}
      </Menu.Item>
    )
  }

  return (
    <Row type="flex" justify='space-between' style={{fontFamily:"Bedrock"}}>
    <Menu
      onClick={handleClick}
      selectedKeys={[current]}
      mode="horizontal" >
        {menuItem('new', 'mail', 'Confirmation Pending')}
        {menuItem('confirmed', 'check', 'Confirmed')}
        {menuItem('waiting', 'meh', 'Pending reports')}
        {menuItem('partially_received', 'file', 'Partially received reports')}
    </Menu>
    <Menu
      onClick={handleClick}
      selectedKeys={[current]}
      mode="horizontal" >
        {menuItem('uploaded_recently', 'file', 'Uploaded recently')}
        {menuItem('today', 'calendar', 'Today')}
        {menuItem('tomorrow', 'smile', 'Tomorrow')}
        {menuItem('all', 'smile', 'All')}          
    </Menu>
    </Row>
  )
});
export default AppointmentsMenu;
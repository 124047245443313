import React from 'react';

const withErrorBoundaryHOC = (Component) => (
  class extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        isError: false,
        errorInfo: null
      }
    }

    componentDidCatch(error, errorInfo) {
      // Catch errors in any components below and re-render with error message
      this.setState({
        isError: true,
        errorInfo: errorInfo
      })
      // You can also log error messages to an error reporting service here
      window.Sentry && window.Sentry.captureException(error)
    }

    render() {
      const { isError } = this.state;
      if(isError){
        return <div>500 internal server error.</div>
      }
      return (<Component {...this.props}/>);
    }
  }
)

export default withErrorBoundaryHOC;
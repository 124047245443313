import React, { useState } from "react";
import { Button, Input } from 'antd'

const ColumnDropDown = React.memo(({columnKey, fetchAppointments, updateFilters, placeholder}) => {

  const [searchInput, setSearchInput] = useState('');

  const onChangeInput = (e) => {
    const value = e.target.value;
    setSearchInput(value);
    updateFilters(columnKey, value);
  }

  const onDelete = () => {
    setSearchInput('');
    updateFilters(columnKey, undefined);
    fetchAppointments();
  }

  return (
    <div className="custom-filter-dropdown">
      <Input
        placeholder={placeholder}
        value={searchInput}
        onChange={onChangeInput}
        onPressEnter={fetchAppointments}
      />
      <Button type="primary" onClick={fetchAppointments}>Filter</Button>
      <Button icon="delete" type="danger" style={{marginLeft: "5px"}} onClick={onDelete} />
    </div>
  )
});
export default ColumnDropDown;
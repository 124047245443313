import React, { useState } from 'react';
import {validateEmail} from '../../utils/util';
import { Button, DatePicker, Input, message, Modal,   Popover } from 'antd'
import { EXPORT_TO_CSV } from '../../routes/api';
import Api from '../../services/api';;

const { RangePicker } = DatePicker;

const DownloadCsvModal = React.memo(({showModal, onChangeShowModal}) => {

  const [errors, setErrors] = useState({});
  const [email, setEmail] = useState('');
  const [showCsvLoader, setShowCsvLoader] = useState(false);
  const [dateRange, setDateRange] = useState({fromDate: undefined, toDate: undefined});


  const onDateRangeChange = (dates, dateStrings) => {
    setDateRange({fromDate: dateStrings[0], toDate: dateStrings[1]}) 
  }

  const renderMasterDateRange = () => {
    return(
      <div>
        <RangePicker onChange={onDateRangeChange} style={{marginTop: 20, width: '100%'}}/>
        {errors["master_range"] && <p style={{color: "red"}}>{errors["master_range"]}</p> }
        {errors["master_status"] && <p style={{color: "red"}}> {errors["master_status"]}</p> }
      </div>
    )
  }

  const handleCancel = e => {
    setShowCsvLoader(false);
    setDateRange({fromDate: undefined, toDate: undefined});
    setEmail('');
    setErrors({});
    onChangeShowModal(false);
  };

  const exportToCsv = (e) => {
    if (!handleValidation())
      return;
    const { fromDate, toDate } = dateRange;
    let url = `${EXPORT_TO_CSV}?master_from_date=${fromDate}&master_to_date=${toDate}&email=${email}&status=all`

    setShowCsvLoader(true);
    Api.exportToCSV(url, {}, 
      ()=>{ // onsuccess call back function
        message.info('Appointments data will be sent to provided email. ',5);
        handleCancel() },
      ()=>{ // onfailure call back function
        setShowCsvLoader(false) }
    );
  }

  const renderCsvLoader = () => {
    if(showCsvLoader === true){
      return(<Button style={{'marginTop': 20}} type="primary" loading >Submitting... </Button>)
    }
    else{
      return(
        <div style={{'marginTop': 20}}>
          <Popover content="Send Email" trigger="hover">
              <Button type="primary" onClick={exportToCsv}> Submit</Button>
          </Popover>
        </div> )
    }
  }

  const renderEmailInput = () =>{
    const message = errors["master_download_email"];
    return ( 
      <div style={{'marginTop': 20}}>
        Please enter a valid email to receive appointments data as csv.
        <div>
          <Input
             placeholder="Email"
             value={email}
             onChange={(e) => {setEmail(e.target.value)}} />
          { message && <p style={{color: "red"}}>{message}</p> }
        </div>
      </div>
    );
   }

  const handleValidation = () => {
    let valid = true
    let errors = {}
    const { fromDate, toDate } = dateRange;
    if (fromDate === undefined || toDate === undefined){
      errors['master_range'] = 'Date range must be specified..'
      valid = false
    }

    if(!validateEmail(email)){
      errors['master_download_email'] = 'Not a valid email.'
      valid = false;
    }
    setErrors(errors);
    return valid
  }

  if(!showModal) {
    return ''
  }
  return(
    <Modal maskClosable={false} visible={showModal} onCancel={handleCancel} footer={null} >
      <div>
        <div>{renderMasterDateRange()}</div>
        <div>
          {renderEmailInput()}
          {renderCsvLoader()}
        </div>
      </div>
    </Modal>
  )

})
export default DownloadCsvModal;
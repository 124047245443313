import React from 'react';
import { LoginForm } from '../components/organisms';

const EnterpriseLoginForm = () => {
  return(
    <LoginForm loginType = {'Pan India Login'}/>
  )  
}

export default EnterpriseLoginForm;

import React, { useState, useEffect } from 'react';
import { Icon, Button, Tag, Table, DatePicker, message, Tooltip, Row, Col } from 'antd'
import { caratRight } from '../assets/icons';
import { FETCH_APPOINTMENT_URL } from '../routes/api';
import { diffInDays } from '../utils/util';
import Api from "../services/api";
import UpdateRecordsModal from './modals/UpdateRecordsModal';
import ColumnDropDown from './ColumnDropDown';
import SelectDropDown from './SelectDropDown';
import ConfirmActionModal from './modals/ConfirmActionModal';
import RejectActionModal from './modals/RejectActionModal';
import ShowPendingTestModal from './modals/ShowPendingTestModal';
import moment from 'moment';

const { RangePicker } = DatePicker;
const filters = [];
const AppointmentTable = React.memo(({searchQuery, currentTab, updateTotal, setLoading}) => {

  
  const filtersParams = { 'package_name': 'package_name', 'appointment_id': 'appointment_id', branch: 'branch', name: 'first_name', vendor_status: 'vendor_status' }
  const statusList = ['new', 'confirmed', 'noshow', 'cancelled', 'rejected', 'rescheduled', 'completed', 'partial_received', 'partially_received', 'received', 'request_reschedule', 'received_reports' ]
  const [enabledFilter, setEnabledFilter] = useState('');
  const [reportReceiveModal, setreportReceiveModal] = useState(false);
  const [confirmActionModal, setConfirmActionModal] = useState(false);
  const [rejectActionModal, setRejectActionModal] = useState(false);
  const [showPendingTestModal, setShowPendingTestModal] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState('');

  const [appointmentsInfo, setAppointmentsInfo] = useState({
    appointments: [],
    total: 0,
    meta: [],
    currentPage: 1,
    sort_order: 'ascend'
  });

  useEffect(() => {
    appointmentsInfo['currentPage'] = 1;
    setAppointmentsInfo(appointmentsInfo);
    fetchAppointments();
  },[currentTab, searchQuery]);

  const updateEnabledFilter = (key, visible) => {
    const newKey = visible ? key : undefined;
    setEnabledFilter(newKey);
  }

  const updateFilters = (key, value) => {
    if(!value){
      delete filters[key];
      return;
    }
    filters[key] = value;
  }

  const onDateSearch = (dates, dateStrings) => {
    if(dateStrings[0] === "")
      delete filters['dateRange'];
    else
      filters['dateRange'] = [ dateStrings[0], dateStrings[1] ];
    fetchAppointments();
  }

  const onPageChanged = (pagination, filters, sorter) => {
    appointmentsInfo.currentPage = pagination.current;
    appointmentsInfo.sort_order = sorter.sort_order;
    setAppointmentsInfo(appointmentsInfo);
    fetchAppointments();
  }

  const onFetchAppointments = (json) => {
    if(!json){
      setLoading(false);
      return;
    }
    json = json.data;
    let total = json.meta && json.meta.total ? json.meta.total : 0
    appointmentsInfo['appointments'] = json.appointments;
    appointmentsInfo['total'] = total;
    appointmentsInfo['meta'] = json.meta;
    setLoading(false);
    updateTotal(total);
    setAppointmentsInfo(appointmentsInfo); 
  }

  const onErrorFetchAppointments = (error) =>  setLoading(false);

  const fetchAppointments = () => {
    setLoading(true);
    const url = getUrl();
    Api.fetchAppointments( url, {}, 
      onFetchAppointments, 
      onErrorFetchAppointments );
    setEnabledFilter('');
  }

  const generateSort = () => {
    let sort_order = 'asc'
    if (appointmentsInfo.sort_order === 'descend') {
      sort_order = 'desc'
    }
    let query = '&sort_field=appointment_date&sort_order=' + sort_order
    return query
  }

  const getUrl = () => {
    
    let url = FETCH_APPOINTMENT_URL(currentTab);

    url = `${url}?page=${appointmentsInfo.currentPage}${generateSort()}`
    for(let key in filters) {
      if(key === 'dateRange')
        continue;
      let value = filters[key];
      url = `${url}&${filtersParams[key]}=${value}`
    }
    if(filters['dateRange']){
      url = `${url}&from_date=${filters['dateRange'][0]}&to_date=${filters['dateRange'][1]}`
    }
    if(searchQuery){
      url = `${url}&term=${searchQuery}`
    }
    if(["new1","completed"].includes(currentTab)){
      const status = currentTab === "new1"?'new':currentTab;
      url = `${url}&status=${status}`;
    }
    return url;
  }
  
  const renderActionButtons = (record) => {
      if (record.vendor_status === 'New' || record.vendor_status === 'Rescheduled') {
        return(
        <div style={{display:'flex', flexDirection:'row'}}>
          <Button onClick={() => {handleActions(record, 'confirm')}} style={{marginBottom:'5px', color:'white', backgroundColor:"#00A04F"}}> Confirm</Button>
          <span style={{marginLeft: "10px"}}>  </span>
          <Button onClick={()=> {handleActions(record, 'reject')}} style={{backgroundColor:"#FF6969", color:'white'}}> Reject </Button>
        </div>
        );
      }
      if (record.vendor_status === 'Confirmed') {
        if (new Date(record.appointment_date) > new Date()) {
          return(
          <div>          
            <Button onClick={()=> {handleActions(record, 'reject')}} style={{backgroundColor:"#FF6969", color:'white', width:"100%"}}> Reject </Button>
          </div>
          );
        } 
        return ( 
          <div style={{display:'flex', flexDirection:'column'}}>
            <Button title="Completed" onClick={() => {handleActions(record, 'completed')}} style={{marginBottom:'10px', color:'white', backgroundColor:"#00A04F"}}>Completed</Button>
            <span style={{marginLeft: "10px"}}>  </span>
            <Button title="No show" onClick={() => {handleActions(record, 'noshow')}} style={{color:'#FF6969', backgroundColor:"#FEF1F1", border:'none'}}> No show </Button>
          </div>
        );
      }
      if (record.vendor_status === 'Completed' || record.vendor_status === 'Partially Received') {
        if (new Date(record.appointment_date) > new Date()) {
          return <div></div>
        }
        return ( 
          <Button type="primary" onClick={() => {handleActions(record, 'received')}}> Upload Reports</Button>
        );
      }
      return <div></div>
  }

  const handleActions = (record,action) => {
    setLoading(true);
    let current = appointmentsInfo?.appointments?.filter((x) => parseInt(x.id) === parseInt(record?.id))[0];
    setSelectedAppointment(current);

    if(action === "partial" || action === "received" ) {
       setreportReceiveModal(true);
       return;
    }

    if(action === "confirm") {
      setConfirmActionModal(true);
      return;
    }
    if(action === "reject") {
      setRejectActionModal(true);
      return;
    }
    modifyRecords(record.id, action);
  }

  const onRecordsModified = (json) => {
    setLoading(false);
    message.info('Updated succesfully.');
    fetchAppointments();
  }

// Modifies the records in the server
  const modifyRecords = (id,action) => {
    Api.updateRecords(
      { id: id, action: action},
      onRecordsModified,
      (json) => {message.info(json)}
    )
  }

  const renderModal = () =>{
    if(reportReceiveModal === true){
      return (<UpdateRecordsModal 
        visible={reportReceiveModal} 
        appointment={selectedAppointment}
        onSuccess = {onRecordsModified}
        closeModal={hideModal} />);
    } else{
      return(<div/>)
    }
  }
  const openTestModal = (selectedAppointment) =>{
    setShowPendingTestModal(true);
    setSelectedAppointment(selectedAppointment);
  }

  const closeTestModal = () => {
    setLoading(false);
    setShowPendingTestModal(false);
  }

  const hideModal = () => {
    setLoading(false);
    setreportReceiveModal(false);
  }

  const onStatusModalCancel = () => {
    setConfirmActionModal(false);
    setRejectActionModal(false);
    setLoading(false);
  }

  const onStatusModalSuccess = () => {
    setConfirmActionModal(false);
    message.success('Updated succesfully.');
    fetchAppointments();
  }
  const onRejectStatusModalSuccess = () => {
    setRejectActionModal(false);
    message.success('Updated succesfully.');
    fetchAppointments();
  }
  const inputFilter = (key, placeholder, title, options = {}, dropdown= false, dropdownList= []) => {
    const openTest = (vendor_status, currentTab) => {
      const clickableAppointment = ['waiting', 'partially_received'].includes(currentTab);
      const clickableAppointmentForStatus = ['Completed', 'Received', 'Partially Received'].includes(vendor_status);
      if(clickableAppointment && clickableAppointmentForStatus ){
        return true;
      }
      return false;
    }

    return  {
      title: title,
      dataIndex: key,
      key: key,
      ...options,
      render: (text, record) => {
          if(key==='name')
           return (
            <Row type='flex' justify="space-between">
               <div>
                  <b style={{color:'#4D6189'}}>{text}</b>
                  <span style={{borderRadius:'100px', margin:'3px', padding:'4px', color:'#008858', backgroundColor:'#E2FCED', textAlign:'center'}}>{record.age_and_gender}</span>
               </div>
                  {record.mobile_number && <p>{record.mobile_number}</p>}
            </Row>
           )
           if(key==="appointment_id" && openTest(record.vendor_status, currentTab))
           return (
            <Col>
             <text>{text}</text>
             <Row type='flex' justify="start" style={{color: '#2168f3', cursor: 'pointer'}} onClick={() => openTestModal(record)}>
               <b style={{paddingRight:'5px', marginLeft: '-16px'}}>View pending report</b>
               <img src={caratRight}/>
             </Row>
            </Col>
           )
          return `${text}`
        },
        filterDropdown: dropdown ?
        (
          <SelectDropDown updateFilters={updateFilters} fetchAppointments={fetchAppointments} placeholder={placeholder} columnKey={key} dropdownList={dropdownList}/>
        ) : (
          <ColumnDropDown updateFilters={updateFilters} fetchAppointments={fetchAppointments} placeholder={placeholder} columnKey={key} />
        ),
      filterIcon: <Icon theme='filled' type="filter" style={{ color: filters[key] ? '#108ee9' : '#4D6189' }} />,
      filterDropdownVisible: enabledFilter === key,
      onFilterDropdownVisibleChange: visible => updateEnabledFilter(key, visible)
    };
  }

  const columns = () => {
    let columns = [];
    columns.push(dateColumn());
    columns.push(inputFilter('appointment_id', 'Appointment ID', 'APPOINTMENT ID' ,{ width:150}));
    columns.push(inputFilter('branch', 'branch', 'BRANCH',{ width:110}));
    columns.push({
      title: "TIME SINCE CREATION",
      dataIndex: "time_since_create",
      key: "time_since_create",
      width: 155,
      render: (text, record) => {
        const { time_since_create_color } = record;
        let color;
        let backgroundColor;
        if (time_since_create_color === "red") {
          color = "#D74651";
          backgroundColor = "#FEEEEE";
        }
        if (time_since_create_color === "yellow") {
          color = "#C2801D";
          backgroundColor = "#FEFBED";
        }
        if (time_since_create_color === "green") {
          color = "#008858";
          backgroundColor = "#E2FCED";
        }
        return (
          <div
            style={{
              cursor: "pointer",
              backgroundColor,
              borderRadius: "100px",
              padding:'1px',
              color,
              textAlign: "center",
              width: "65px",
            }}
          >
            {record.time_since_create} Hrs
          </div>
        );
      },
    });
    columns.push({ title: 'HOME COLL.', dataIndex: 'home_collection', key: 'home_collection',width:80,
      render: (text, record) => {
        if(text === true){
          return("YES")
        }
        else{
          return("NO")
        }
      } 
    });
    (currentTab === "all") && columns.push(inputFilter('vendor_status', 'Status', 'STATUS',{ width: 160, render: (text,record) => { return (record.vendor_status) }}, true, statusList ));
    columns.push({ title: 'NATIONAL IDENTITY', dataIndex: 'national_identity_number', key: 'national_identity_number', width: 180 })
    columns.push(inputFilter('name', 'Customer details', 'CUSTOMER DETAILS',{ width:170}));
    const package_column = inputFilter('package_name', 'Package Name', 'PACKAGE NAME',{ width: 160, render: (text,record) => { return text.join(', ') }});
    columns.push(package_column);
    columns.push({ title: 'SAMPLE COLLECTION ADDRESS', dataIndex: 'home_address', key: 'home_address', width: 180,
      render: (text, record) => {
        if(text && text.length > 8){
          if(record.home_collection)
          return(
            <div style={{cursor: 'pointer', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
              <Tooltip placement="topLeft" title={text}>
                <span>{text}</span>
              </Tooltip>
            </div>
          )
        }
        if( (text && text.length > 8) || (!record.home_collection) ) {
          return ("----------------")
        }
      }
    });
    columns.push({ title: 'ACTION', key: 'action', width: 160,
      render: (record) => ( renderActionButtons(record) ),
    });
    return columns;
  }

  const dateColumn = (key) => {
    return  {
         title: 'DATE & TIME',
         dataIndex: ['appointment_date','time'],
         key: 'appointment_date',
         width: 110,
         render: (text,record) => {
           if(diffInDays(new Date, record.appointment_date) > 2)
              return (<div style={{display:'flex',flexDirection:'column'}}>
                <Tag color="red"> {moment(record.appointment_date).format('DD MMM YYYY')} </Tag>       
                <Tag color="red"> {record.time} </Tag>
              </div>)
            return (<div style={{display:'flex',flexDirection:'column'}}>
              <div color="red">{moment(record.appointment_date).format('DD MMM YYYY')}</div>
              <div color="red"> {record.time} </div>
            </div>)                            
         },
         filterDropdown: (
           <div className='custom-filter-dropdown'>
             <RangePicker onChange={onDateSearch} />
           </div>
         ),
         filterIcon: <Icon type="filter" theme='filled' style={{ color: filters['dateRange'] ? '#108ee9' : '#4D6189'  }} />,
         filterDropdownVisible: enabledFilter === 'dateRange',
         onFilterDropdownVisibleChange: visible => { updateEnabledFilter('dateRange', visible ); }
       }
   }
  const { appointments, total, currentPage } = appointmentsInfo;
  return (
  <div style={{border:'1px solid #E5E8F0', borderRadius:'5px'}}>
    <div style={{backgroundColor:'#FAFCFF', margin:'20px', border:'1px solid #E5E8F0', borderRadius:'4px'}} >
        <Table className="table_bg" scroll={{ x: 1500 }} rowKey="id" dataSource={appointments} 
          pagination={{pageSize: 20, total: total, current: currentPage}} 
          onChange={onPageChanged} columns={columns()} />
      {renderModal()}
      <ShowPendingTestModal width='large' currentTab={currentTab} visible={showPendingTestModal} selectedAppointment={selectedAppointment} onClose={closeTestModal} handleUpload={handleActions}/>
      <ConfirmActionModal selectedAppointment={selectedAppointment} visible={confirmActionModal} onCancel={onStatusModalCancel} onSuccess={onStatusModalSuccess} />
      <RejectActionModal selectedAppointment={selectedAppointment} visible={rejectActionModal} onCancel={onStatusModalCancel} onSuccess={onRejectStatusModalSuccess} />
    </div>
  </div>
  )
});
export default AppointmentTable;

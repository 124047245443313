import React from 'react'
import '../../index.css'
import { exclamatoryInfoIconYellow, exclamatoryBgGrayUp, exclamatoryBgGray, exclamatoryRedDark, correctTick } from '../../assets/icons'
import { Col, Row } from 'antd'
const bgColor = {'red':'#FEEEEE', 'green':'#DBFBE9', 'blue': '#BAD0FC', 'yellow' : '#FEFBED'}
const bannerIcons = {'red':exclamatoryRedDark, 'green':correctTick, 'blue': exclamatoryBgGrayUp, 'yellow': exclamatoryInfoIconYellow}

const UserAlert = ({btnText, para, icon, btnHandler=()=> {}}) => {
  return (
    <div  style={{ background: bgColor[icon] || '#F5F8FF', borderRadius : '8px' , padding : '12px 16px', border : '1px solid #ffed4a', marginBottom : '12px'}}>
    <div style={{ display : 'flex', inDirection : 'row', }}>
      <img
        src={bannerIcons[icon] || exclamatoryBgGray}
        alt='banner-icon'
        style={{padding : '0px 8px', width: '40px'}}
      />
      <Row type='flex' justify='space-between' style={{ width : '100%'}}>
        <Col  style={{ padding : '12px 0px'}}>{para}</Col>
        <Col>
       {btnText && (
          <button style={{ color : 'white' , backgroundColor : '#0951e1', borderRadius : '8px', padding : '8px 16px', cursor : 'pointer' }} onClick={btnHandler}>
            {btnText} 
          </button>
        )}
        </Col>
      </Row>
    </div>
  </div>
  )
}

export default UserAlert
import React, {Component} from 'react'
import { Row, Col, Layout, Input, Icon, Button, Form, message, Spin } from 'antd';
import {HOST} from '../Url'
const FormItem = Form.Item;
const { Content } = Layout;

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class ForgotPassword extends Component {
  constructor(props){
    super(props)
    this.state = {
      loading: false
    }
  }
  componentDidMount() {
    // To disabled submit button at the beginning.
    this.props.form.validateFields();
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.submitEmail(values.email)
      }
    });
  }
  submitEmail (email) {
    this.setState({ loading: true })
    let that = this
    let type = this.props.match.params.type
    fetch(HOST + "/forgot_password?type="+type ,{
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email
        })
      })
      .then(response => {
        if (response.status === 200) {
            message.success("Link has been sent to email successfully")
            this.setState({ loading: false })
            that.props.history.push('/login')
        } else {
          this.setState({ loading: false })
          message.error("Account is not available with this email or deactivated, please contact ekincare for more information")
        }
      }, this)
      .catch((error) => {
          console.error(error);
          this.setState({ loading: false })
        });
  }  

  render() {
    const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;
    const emailError = isFieldTouched('email') && getFieldError('email');
    return (
      <Layout>
       <Content style={{ padding: '0 0px', marginTop: 0 }}>
          <Spin spinning={this.state.loading} tip='loading...'>
            <div style={{ background: '#fff', padding: 50, minHeight: 380 }}>
              <Row type="flex" justify="space-around" align="middle">
                <Col span={7}>
                  <h2> Forgot Password? </h2>
                  <h4>Please enter you registered email. An email will be sent to the mail with a link to reset your password.</h4>
                  <br/>
                  <Form onSubmit={this.handleSubmit}>
                    <FormItem
                      validateStatus={emailError ? 'error' : ''}
                      help={emailError || ''}
                    >
                      {getFieldDecorator('email', {
                        rules: [{ required: true, message: 'Please input your email!' }],
                      })(
                        <Input prefix={<Icon type="user" style={{ fontSize: 13 }} />} placeholder="email" />
                      )}
                    </FormItem>
                    <FormItem>
                      <Button type="primary" htmlType="submit" disabled={hasErrors(getFieldsError())}> Submit </Button>
                    </FormItem>
                  </Form>
                </Col>
              </Row>
            </div>
          </Spin>
        </Content>
      </Layout>);
  }
}
const WrappedForgotPassword = Form.create()(ForgotPassword);

export default WrappedForgotPassword
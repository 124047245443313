import React, { useState } from "react";
import { Select, Button, Row } from 'antd';
const { Option } = Select;

const SelectDropDown = React.memo(({ columnKey, fetchAppointments, updateFilters, placeholder, dropdownList }) => {
  
    const [selectedValues, setSelectedValues] = useState([]);

    const onDelete = () => {
        setSelectedValues([]);
        updateFilters(columnKey, undefined);
        fetchAppointments();
    }

    const handleDropdownChange = (values) => {
        setSelectedValues(values);
        updateFilters(columnKey, values);
        fetchAppointments(values); // Call fetchAppointments when dropdown selection changes
    };

    return (
    <div className="custom-filter-dropdown">
        <Select mode="multiple" style={{width: '100%' }} 
                placeholder={placeholder}
                value={selectedValues}
                onChange={handleDropdownChange}
        >
        {dropdownList.map((item, index) => (
          <Option key={index} style={{padding: '5px 5px', 'font-size': '12px' }} value={item}>{item}</Option>
        ))}
        </Select>
        
            <Button type="primary" onClick={() => { updateFilters(columnKey, selectedValues); }}
            style={{ marginTop: '10px', width: '60%'  }}
            >Filter</Button>

            <Button icon="delete" type="danger" style={{marginLeft: '10px', marginTop: '10px', width: '30%' }} onClick={onDelete} ></Button> 
  
    </div>)
});
export default SelectDropDown;
import React, {useState, useEffect} from "react";
import { storeToken, storeDisplayName } from '../../Auth'
import Api from "../../services/api";
import {Icon, Input, Button, Row, Col, Alert } from 'antd';
import {
  Redirect, Link
} from 'react-router-dom'
const LoginForm = ({ loginType }) => {
  const [errors, updateErrors] = useState({});
  const [data, updateData] = useState({
    loginLink: "",
    forgotPasswordLink: "",
    loginViewText: "",
    requestUrl: ""
  })
  
  const [notAuthorized, updateNotAuthorized] = useState(false);
  const [loggedIn, updateLoggedIn] = useState(false);
  const errorMessage = "Username/Password does not match";
  const window_height = window.innerHeight;
  const [credentials, updateCredentials] = useState({
    email: '',
    password: ''
  });

  useEffect(() => {
    if (loginType === 'Branch Login') {
      data.loginLink = "/admin/login";
      data.forgotPasswordLink = "/provider/forgotPassword";
      data.loginViewText = "For pan India view";
      data.requestUrl = "/login";
    } else {
      data.loginLink = "/login";
      data.forgotPasswordLink = "/enterprise/forgotPassword";
      data.loginViewText = "For branch view";
      data.requestUrl = "/enterprise/login";
    }
    updateData({ ...data })
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    updateNotAuthorized(false)
    var IsFormValid = handleValidation();
    if (IsFormValid) {
      handleLogin();
    }
  }

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;

    if (
      !credentials.email.match(
        /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      formIsValid = false;
      errors["email"] = " Enter a valid Email Address";
    }

    if (!credentials.email) {
      formIsValid = false;
      errors["email"] = " Cannot be empty";
    }

    if (!credentials.password) {
      formIsValid = false;
      errors["password"] = " Cannot be empty";
    }

    updateErrors(errors);
    return formIsValid;
  };

  const updateDetails = e => {
    const { name, value } = e.currentTarget;
    credentials[name] = value;
    updateCredentials({ ...credentials });
  };

  const handleLogin = () => {
   let body = JSON.stringify({
      email: credentials.email,
      password: credentials.password,
    })
    if (loginType === 'Branch Login') {
      Api.branchLogin(
        { data: body },
        onLoginSuccess,
        onLoginFailure
      );
    } else{
      Api.enterpriseLogin(
        { data: body },
        onLoginSuccess,
        onLoginFailure
      );
    }
  }

  const onLoginSuccess = response => {
    const headers = response.headers
    let key = headers['x-provider-key']
    if(key !== undefined && key.length > 20) {
      storeToken(headers['x-provider-key'])
      updateLoggedIn(true)
      if (loginType === 'Branch Login') {
        storeDisplayName('Branch - '+response['data']['display_name'])
      } else {
        storeDisplayName('Admin - '+response['data']['display_name'])
      }
      return response
    }
  }

  const onLoginFailure = () => {
    updateNotAuthorized(true)
  }

  const renderForm = () => {
    return (
      <Row style={{minHeight: window_height, height: '100%'}}>
        <Col md={3} />
        <Col md={14} sm={0} xs={0}>
        {notAuthorized 
          ? <Alert message="Warning" closable description={errorMessage} type="error" showIcon banner/> 
          : null
        }
        <img style={{minHeight: window_height, maxHeight: '100%', maxWidth: '70%'}} alt="Client Logo" title="Client Logo" src="https://res.cloudinary.com/ekincare/image/upload/v1541146334/hr_dashboard_login_page/HR.svg" />
        </Col>

        <Col md={7} sm={0} xs={0} className="">
          <Row justify="space-around" align="middle" style={{paddingRight: '', width: '75%'}}>
            <Col id="components-form-demo-normal-login" style={{paddingTop: 200, position: 'absolute'}}>
              <br/>
              <div className="logo">
                <img style={{maxWidth: '100%'}} src="https://res.cloudinary.com/ekincare/image/upload/v1541072763/ekincare-logo-new-color.svg" alt=""/> 
              </div>
              <br/>
              <h2>{loginType}</h2>
                <Input
                  style={{height: '42px'}}
                  value={credentials.email}
                  prefix={<Icon type="user" style={{color: 'rgba(0,0,0,.25)' }} />}
                  placeholder="Email"
                  name="email"
                  onChange={updateDetails}
                /><br/>
                <p style={{ color: "red" }}>{errors["email"]}</p>
                  
                <Input
                  style={{height: '42px'}}
                  prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)' }} />}
                  value={credentials.password}
                  type="password"
                  placeholder="Password"
                  name = "password"
                  onChange={updateDetails}
                />
                <p style={{ color: "red" }}>{errors["password"]}</p>
              
                <Button id='loginButton' size='large' type="primary" htmlType="submit" className="login-form-button" style={{fontWeight: '600'}} onClick={(e)=>handleSubmit(e) }>
                  Log in
                </Button>
                <Link to={data.forgotPasswordLink} style={{paddingLeft: 10}}> Forgot password?</Link>
                <br/><br/>
              
              <font size="4">{data.loginViewText}
              <Link to={data.loginLink} style={{color: 'blue'}}> login here</Link></font>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  return( 
    <div>{(() => {
        if (loggedIn) {
          return (
            <div><Redirect to={"/"} /></div>
          )
        } else {
          return (
            <div>{renderForm()}</div>
          )
        }
      })()}
    </div>
  )

};

export default LoginForm;

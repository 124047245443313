import React from 'react';
import { LoginForm } from '../components/organisms';

const ProviderLoginForm = () => {
  return(
    <LoginForm loginType = {'Branch Login'}/>
  )  
}

export default ProviderLoginForm;

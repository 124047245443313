import React from 'react';
import ReactDOM from 'react-dom';
import AppointmentsPage from './pages/AppointmentsPage';
import WrappedLoginForm from './pages/ProviderLoginForm';
import ForgotPassword from './pages/ForgotPassword'
import ResetPassword from './pages/ResetPassword'
import WrappedEnterpriseLoginForm from './pages/EnterpriseLoginForm';
import LocaleProvider from 'antd/lib/locale-provider';
import enUS from 'antd/lib/locale-provider/en_US';
import {isLoggedIn} from './Auth'
import './index.css';
import './override.css'
import {
  BrowserRouter as Router,
  Route,
  Redirect
} from 'react-router-dom'
import withErrorBoundaryHOC from './utils/hocs/withErrorBoundaryHOC';


const PrivateRoute = ({ component, ...rest }) => (
  <Route {...rest} render={props => (
    isLoggedIn() ? (
      React.createElement(component, props)
    ) : (
      <Redirect to={{
        pathname: '/login',
        state: { from: props.location }
      }}/>
    )
  )}/>
)

const HomeComponent = ({}) => (
  <LocaleProvider locale={enUS}>
    <Router>
      <div>
        <PrivateRoute exact path='/' component={AppointmentsPage} />
        <Route path='/login' component={WrappedLoginForm} />
        <Route path='/admin/login' component={WrappedEnterpriseLoginForm} />
        <Route exact path='/:type/forgotPassword' component={ForgotPassword} />
        <Route exact path='/:type/resetPassword/:id' component={ResetPassword} />
        <PrivateRoute path={'/appointments'} component={AppointmentsPage}/>
        </div>
    </Router>
</LocaleProvider>
)

const WrappedComponent = withErrorBoundaryHOC(HomeComponent)


ReactDOM.render(
  <WrappedComponent />,
  document.getElementById('root')
  );
